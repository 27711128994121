import {useEffect, useContext} from 'react';
import isNil from 'lodash/isNil';
import {Application} from '../../components/loan-applications/loan-application/loan-application.model';
import {Process} from '../model/process.model';
import {AppSnackbarContext} from '../../components/shared/app-snackbar-provider/AppSnackbarProvider';

export function useCheckInactivePaymentIntervals(process: Process, application?: Application): void {
  const {showErrorMessage, closeMessage} = useContext(AppSnackbarContext);

  useEffect(() => {
    const simulations = application?.simulations;
    const paymentIntervalIds = process?.loanProduct?.paymentIntervalIds;
    let hasPaymentIntervalError = false;
    if (!isNil(simulations) && !isNil(paymentIntervalIds)) {
      const outdatedSimulations = simulations?.filter(simulation => !paymentIntervalIds.includes(simulation.input.paymentIntervalId)) || [];
      if (outdatedSimulations.length > 0) {
        hasPaymentIntervalError = true;
        const phases = process.phases.filter(phase => outdatedSimulations
          .some(simulation => simulation.phaseId === phase.id))
          .map(simulation => simulation.name)
          .join(' & ');
        showErrorMessage(`Payment interval(s) no longer active from this loan product. Please update phase: (${phases})`, true);
      }
    }
    if (hasPaymentIntervalError) {
      return () => {
        closeMessage();
      };
    }
  }, [process, application]);
}