export enum FileUploadErrorCode {
  FILE_TOO_LARGE,
  FILE_TYPE_NOT_SUPPORTED
}

export type FileUploadError = {
  errorCode: FileUploadErrorCode,
  message: string,
}

export enum FileTypes {
  APPLICATION_PDF = 'application/pdf',
  IMAGE_PNG = 'image/png',
  IMAGE_JPG = 'image/jpg',
  IMAGE_JPEG = 'image/jpeg',
  CSV = '.csv',
  AUDIO_MP3 = 'audio/mp3',
  AUDIO_MP4 = 'audio/mp4',
  AUDIO_AAC = '.aac',
  AUDIO_M4A = 'audio/x-m4a',
  VIDEO_MP4 = 'video/mp4'
}

export enum MaximumFileSize {
  DEFAULT = 4194304,
  IMAGE = 5242880,
  BATCH = 5242880,
  AUDIO_VIDEO = 52428800
}

export type AcceptedFileProperties = {
  types: FileTypes[]
  maxSize?: MaximumFileSize
}