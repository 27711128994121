import {NxFormikInput, NxFormikSelect, NxFormikTextarea} from '@nextbank/ui-components';
import {useField} from 'formik';
import React, {ReactElement, ReactNode, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {ACCEPTED_AUDIO_FILE_TYPES, ACCEPTED_DOCUMENT_FILE_TYPES, ACCEPTED_VIDEO_FILE_TYPES} from '../../../../../../../constants/file-uploads';
import useFileUpload from '../../../../../../../shared/hooks/use-file-upload.hook';
import {ConfiguredField, DocumentField, DocumentFieldTO} from '../../../../../../../shared/model/field.model';
import {getDocumentTypeOptions} from '../../../../../../../utils/custom-fields-utils';
import {TransHelper} from '../../../../../../../utils/trans-helper';
import FormGroup from '../../../../../../shared/form-column/FormGroup';
import NxFileUpload from '../../../../../../shared/inputs/nx-file-upload/NxFileUpload';
import {StepContext} from '../../loan-application-step/LoanApplicationStep';
import {FieldDisablementReason} from '../field-disablement-reason.model';
import {ConfiguredFieldWrapper as FieldWrapper} from '../ConfiguredFieldWrapper';
import {ConfiguredDocumentField, ConfiguredDocumentFieldError} from './configured-document-field.model';
import {ConfiguredDocumentInputGroupHeader as GroupHeader} from './ConfiguredDocumentInputGroupHeader';
import styles from './ConfiguredDocumentInputGroup.module.scss';
import {AcceptedFileProperties, MaximumFileSize} from '../../../../../../shared/inputs/nx-file-upload/file-upload.model';

const PrefixTrans = TransHelper.getPrefixedTrans('COMMON.FIELDS');

export interface Props {
  configuredField: ConfiguredDocumentField;
  label: ReactNode;
  name: string;
  disablementReason?: FieldDisablementReason;
}

const acceptedFileProperties: AcceptedFileProperties[] = [
  {
    types: ACCEPTED_DOCUMENT_FILE_TYPES,
    maxSize: MaximumFileSize.DEFAULT
  },
  {
    types: [...ACCEPTED_AUDIO_FILE_TYPES, ...ACCEPTED_VIDEO_FILE_TYPES],
    maxSize: MaximumFileSize.AUDIO_VIDEO
  }
];

export const ConfiguredDocumentInputGroup = ({configuredField, label, name, disablementReason}: Props): ReactElement => {

  const {t} = useTranslation();
  const {downloadFile} = useFileUpload();
  const {isStepReadonly} = useContext(StepContext);
  const disabled = isStepReadonly || !!disablementReason;
  const {enabled, required} = configuredField;
  const [field, meta, helper] = useField<ConfiguredDocumentField>(name);
  const externalError = (meta.error as ConfiguredDocumentFieldError)?.value?.file?.file;
  const currentFieldValue = field.value;

  const handleSelectDocument = useCallback(async (document: DocumentFieldTO): Promise<void> => {

    const file = await downloadFile(document.file);

    const updatedValue = {
      ...currentFieldValue,
      value: {
        ...currentFieldValue?.value,
        ...document,
        file: {...file, isChanged: true}
      }
    };

    helper.setValue(updatedValue);
  }, [currentFieldValue]);

  const handleFileChange = useCallback((updatedFile?: File): Promise<void> | void => {

    const updatedValue = {
      ...currentFieldValue,
      value: {
        ...currentFieldValue?.value,
        file: {file: updatedFile, isChanged: true}
      }
    };

    helper.setValue(updatedValue as ConfiguredField<DocumentField>);
  }, [currentFieldValue]);

  const Header = <GroupHeader label={label} disabled={disabled} onSelect={handleSelectDocument} />;

  return (
    <FormGroup header={Header} className={styles.fields}>
      <FieldWrapper enabled={enabled} disablementReason={disablementReason}>
        <NxFormikSelect emptyOption
                        disabled={disabled}
                        required={required}
                        name={`${name}.value.type`}
                        options={getDocumentTypeOptions(t)}
                        label={<PrefixTrans>TYPE</PrefixTrans>} />
      </FieldWrapper>
      <FieldWrapper enabled={enabled} disablementReason={disablementReason}>
        <NxFormikInput disabled={disabled}
                       required={required}
                       name={`${name}.value.name`}
                       label={<PrefixTrans>NAME</PrefixTrans>} />
      </FieldWrapper>
      <FieldWrapper enabled={enabled} disablementReason={disablementReason}>
        <NxFormikInput disabled={disabled} name={`${name}.value.number`} label={<PrefixTrans>NUMBER</PrefixTrans>} />
      </FieldWrapper>
      <FieldWrapper enabled={enabled} disablementReason={disablementReason}>
        <NxFormikTextarea disabled={disabled} name={`${name}.value.remarks`} label={<PrefixTrans>REMARKS</PrefixTrans>} />
      </FieldWrapper>
      <FieldWrapper enabled={enabled} disablementReason={disablementReason} className={styles.fileUploadWrapper}>
        <NxFileUpload value={currentFieldValue?.value?.file?.file}
                      acceptedFileProperties={acceptedFileProperties}
                      className={styles.fileUpload}
                      disabled={disabled}
                      externalError={externalError}
                      label={<PrefixTrans>FILE</PrefixTrans>}
                      onBlur={(): void => helper.setTouched(true)}
                      onChange={handleFileChange}
                      required={required} />
      </FieldWrapper>
    </FormGroup>
  );
};

