import {NxButton} from '@nextbank/ui-components';
import React, {ReactElement, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {RoutePaths} from '../../../../../../routes/routes.paths';
import {PostRequest} from '../../../../../../shared/hooks/use-post.hook';
import {useGuardedHistory} from '../../../../../router/GuardedHistory';
import {AppSnackbarContext} from '../../../../../shared/app-snackbar-provider/AppSnackbarProvider';
import {StepContext} from '../../shared/loan-application-step/LoanApplicationStep';
import {PrefixTrans, TRANS_PREFIX} from '../Summary';

interface Props {
  submitApplicationRequest: PostRequest<unknown, unknown>;
  withLinkedDepositAccount: boolean
}

export default function ReleaseButton({submitApplicationRequest, withLinkedDepositAccount}: Props): ReactElement {

  const {t} = useTranslation();
  const history = useGuardedHistory();
  const {isStepReadonly} = useContext(StepContext);
  const {showErrorMessage, showSuccessMessage} = useContext(AppSnackbarContext)
  const [isReleasing, setReleasing] = useState(false);

  const onRelease = async (): Promise<void> => {

    setReleasing(true);

    submitApplicationRequest()
      .then((): void => {
        const successMessage = withLinkedDepositAccount 
                            ? `${t(`${TRANS_PREFIX}.RELEASE_SUCCESS_MESSAGE`)} ${t(`${TRANS_PREFIX}.WITH_LINKED_DEPOSIT_ACCOUNT`)}`
                            : t(`${TRANS_PREFIX}.RELEASE_SUCCESS_MESSAGE`);

        showSuccessMessage(successMessage);
        history.push(RoutePaths.LOAN_APPLICATIONS);
      })
      .catch(error => showErrorMessage(error.message))
      .finally(() => setReleasing(false))
  };

  return (
    <NxButton onClick={onRelease} loaded={!isReleasing} disabled={isStepReadonly || isReleasing}>
      <PrefixTrans>RELEASE</PrefixTrans>
    </NxButton>
  );
}
